// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cases-conclusion-index-js": () => import("./../../../src/pages/cases/conclusion/index.js" /* webpackChunkName: "component---src-pages-cases-conclusion-index-js" */),
  "component---src-pages-cases-health-maintenance-primary-care-index-js": () => import("./../../../src/pages/cases/health-maintenance-primary-care/index.js" /* webpackChunkName: "component---src-pages-cases-health-maintenance-primary-care-index-js" */),
  "component---src-pages-cases-index-js": () => import("./../../../src/pages/cases/index.js" /* webpackChunkName: "component---src-pages-cases-index-js" */),
  "component---src-pages-cases-medication-management-gastroenterology-index-js": () => import("./../../../src/pages/cases/medication-management-gastroenterology/index.js" /* webpackChunkName: "component---src-pages-cases-medication-management-gastroenterology-index-js" */),
  "component---src-pages-cases-primary-care-diagnosis-index-js": () => import("./../../../src/pages/cases/primary-care-diagnosis/index.js" /* webpackChunkName: "component---src-pages-cases-primary-care-diagnosis-index-js" */),
  "component---src-pages-cme-index-js": () => import("./../../../src/pages/cme/index.js" /* webpackChunkName: "component---src-pages-cme-index-js" */),
  "component---src-pages-faculty-index-js": () => import("./../../../src/pages/faculty/index.js" /* webpackChunkName: "component---src-pages-faculty-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-index-js": () => import("./../../../src/pages/media/index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-steps-step-1-index-js": () => import("./../../../src/pages/steps/step-1/index.js" /* webpackChunkName: "component---src-pages-steps-step-1-index-js" */),
  "component---src-pages-steps-step-2-index-js": () => import("./../../../src/pages/steps/step-2/index.js" /* webpackChunkName: "component---src-pages-steps-step-2-index-js" */),
  "component---src-pages-steps-step-3-index-js": () => import("./../../../src/pages/steps/step-3/index.js" /* webpackChunkName: "component---src-pages-steps-step-3-index-js" */),
  "component---src-pages-steps-step-4-index-js": () => import("./../../../src/pages/steps/step-4/index.js" /* webpackChunkName: "component---src-pages-steps-step-4-index-js" */),
  "component---src-pages-steps-step-5-index-js": () => import("./../../../src/pages/steps/step-5/index.js" /* webpackChunkName: "component---src-pages-steps-step-5-index-js" */),
  "component---src-pages-steps-step-6-index-js": () => import("./../../../src/pages/steps/step-6/index.js" /* webpackChunkName: "component---src-pages-steps-step-6-index-js" */)
}

